import {createGlobalStyle} from "styled-components";
import {devices, sizes} from "../utils/devices";
import {theme} from "../utils/theme";

export default createGlobalStyle`
  @font-face {
    font-family: 'Montserrat';
    src: url('/fonts/montserrat.ttf');
    font-display: swap;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('/fonts/montserrat.ttf');
    font-display: swap;
    font-weight: 700
  }

  @font-face {
    font-family: 'Montserrat';
    src: url('/fonts/montserrat.ttf');
    font-display: swap;
    font-weight: 500
  }

  @media ${devices.minLaptop} {
    background-image: url('/images/auth/auth-background.jpg');
    background-size: cover;
    background-position: center;
  }

  html {
    @media ${devices.minMobile} {
      font-size: 80%
    }

    @media ${devices.minTablet} {
      font-size: 100%
    }

    @media ${devices.minLaptop} {
      font-size: 100%
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    border: initial;
    -webkit-text-fill-color: initial;
    -webkit-box-shadow: initial;
    transition: background-color 5000s ease-in-out 0s;
  }

  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    //font-family: 'Montserrat', serif;
    font-family: 'Helvetica Neue', serif;
    font-weight: 700;
    background-color: ${theme.colors.background};
  }

  h1 {
    color: ${theme.colors.title}
  }

  input, button, a, img {
    transition: all .3s;
  }

  textarea:focus, input:focus {
    outline: none;
  }

  label strong {
    color: #ca371f;
  }

  button {
    cursor: pointer;
  }

  .row {
    display: flex;
    padding-right: 15px;
    padding-left: 15px;
    flex-wrap: wrap;
  }

  //
  //.row {
  //  display: -webkit-box;
  //  display: -ms-flexbox;
  //  display: flex;
  //  -ms-flex-wrap: wrap;
  //  flex-wrap: wrap;
  //}

  div:focus {
    box-shadow: none;
    outline: none;
  }

  .col1, .col2, .col3, .col4, .col5, .col6, .col7, .col8, .col9, .col10, .col11, .col12, .coltab1, .coltab2 .coltab3,
  .coltab4, .coltab5, .coltab6, .coltab7, .coltab8, .coltab9, .coltab10, .coltab11, .coltab12, .colsm1, .colsm2, .colsm3,
  .colsm4, .colsm5, .colsm6, .colsm7, .colsm8, .colsm9, .colsm10, .colsm11, .colsm12, .collg1, .collg2, .collg3, .collg4,
  .collg5, .collg6, .collg7, .collg8, .collg9, .collg10, .collg11, .collg12 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    box-sizing: border-box;
  }

  @media ${devices.minMobile} {
    .menuBurgerWidth {
      width: 100% !important;
    }

    .col1 {
      max-width: 8.33333%;
      flex: 0 0 8.33333%;

    }

    .col2 {
      max-width: 16.666667%;
      flex: 0 0 16.666667%;
    }

    .col3 {
      max-width: 25%;
      flex: 0 0 25%;
    }

    .col4 {
      max-width: 33.33333%;
      flex: 0 0 33.33333%;
    }

    .col5 {
      max-width: 41.666667%;
      flex: 0 0 41.666667%;
    }

    .col6 {
      max-width: 50%;
      flex: 0 0 50%;
    }

    .col7 {
      max-width: 58.333333%;
      flex: 0 0 58.333333%;
    }

    .col8 {
      max-width: 66.666667%;
      flex: 0 0 66.666667%;
    }

    .col9 {
      max-width: 75%;
      flex: 0 0 75%;
    }

    .col10 {
      max-width: 83.333333%;
      flex: 0 0 83.333333%;
    }

    .col11 {
      max-width: 91.666667%;
      flex: 0 0 91.666667%;
    }

    .col12 {
      max-width: 100%;
      flex: 0 0 100%;
    }

    .offset1 {
      margin-left: 8.33333%;
    }

    .offset2 {
      margin-left: 16.666667%;
    }

    .offset3 {
      margin-left: 25%;
    }

    .offset4 {
      margin-left: 33.33333%;
    }

    .offset5 {
      margin-left: 41.666667%;
    }

    .offset6 {
      margin-left: 50%;
    }

    .offset7 {
      margin-left: 58.333333%;
    }

    .offset8 {
      margin-left: 66.666667%;
    }

    .offset9 {
      margin-left: 75%;
    }

    .offset10 {
      margin-left: 83.333333%;
    }

    .offset11 {
      margin-left: 91.666667%;
    }

    .offset12 {
      margin-left: 100%;
    }
  }

  @media (max-width: 600px) {
    .maxThumbPhone {
      max-width: 400px;
      margin: auto;
    }
  }

  @media (min-width: 600px) {
      //@media ${devices.minTablet} {
      // @media (max-width: ${sizes.laptop}) {
    //   .maxThumbTab {
    //     max-width: 400px !important;
    //     margin: auto;
    //   }
    // }
    .coltab1 {
      max-width: 8.33333%;
      flex: 0 0 8.33333%;
    }

    .coltab2 {
      max-width: 16.666667%;
      flex: 0 0 16.666667%;
    }

    .coltab3 {
      max-width: 25%;
      flex: 0 0 25%;
    }

    .coltab4 {
      max-width: 33.33333%;
      flex: 0 0 33.33333%;
    }

    .coltab5 {
      max-width: 41.666667%;
      flex: 0 0 41.666667%;
    }

    .coltab6 {
      max-width: 50%;
      flex: 0 0 50%;
    }

    .coltab7 {
      max-width: 58.333333%;
      flex: 0 0 58.333333%;
    }

    .coltab8 {
      max-width: 66.666667%;
      flex: 0 0 66.666667%;
    }

    .coltab9 {
      max-width: 75%;
      flex: 0 0 75%;
    }

    .coltab10 {
      max-width: 83.333333%;
      flex: 0 0 83.333333%;
    }

    .coltab11 {
      max-width: 91.666667%;
      flex: 0 0 91.666667%;
    }

    .coltab12 {
      max-width: 100%;
      flex: 0 0 100%;
    }

    .offsettab1 {
      margin-left: 8.33333%;
    }

    .offsettab2 {
      margin-left: 16.666667%;
    }

    .offsettab3 {
      margin-left: 25%;
    }

    .offsettab4 {
      margin-left: 33.33333%;
    }

    .offsettab5 {
      margin-left: 41.666667%;
    }

    .offsettab6 {
      margin-left: 50%;
    }

    .offsettab7 {
      margin-left: 58.333333%;
    }

    .offsettab8 {
      margin-left: 66.666667%;
    }

    .offsettab9 {
      margin-left: 75%;
    }

    .offsettab10 {
      margin-left: 83.333333%;
    }

    .offsettab11 {
      margin-left: 91.666667%;
    }

    .offsettab12 {
      margin-left: 100%;
    }

    @media ${devices.minLaptop} {
      .menuBurgerWidth {
        width: 30% !important;
      }

      .colsm1 {
        max-width: 8.33333%;
        flex: 0 0 8.33333%;
      }

      .colsm2 {
        max-width: 16.666667%;
        flex: 0 0 16.666667%;
      }

      .colsm3 {
        max-width: 25%;
        flex: 0 0 25%;
      }

      .colsm4 {
        max-width: 33.33333%;
        flex: 0 0 33.33333%;
      }

      .colsm5 {
        max-width: 41.666667%;
        flex: 0 0 41.666667%;
      }

      .colsm6 {
        max-width: 50%;
        flex: 0 0 50%;
      }

      .colsm7 {
        max-width: 58.333333%;
        flex: 0 0 58.333333%;
      }

      .colsm8 {
        max-width: 66.666667%;
        flex: 0 0 66.666667%;
      }

      .colsm9 {
        max-width: 75%;
        flex: 0 0 75%;
      }

      .colsm10 {
        max-width: 83.333333%;
        flex: 0 0 83.333333%;
      }

      .colsm11 {
        max-width: 91.666667%;
        flex: 0 0 91.666667%;
      }

      .colsm12 {
        max-width: 100%;
        flex: 0 0 100%;
      }

      .offsetsm1 {
        margin-left: 8.33333%;
      }

      .offsetsm2 {
        margin-left: 16.666667%;
      }

      .offsetsm3 {
        margin-left: 25%;
      }

      .offsetsm4 {
        margin-left: 33.33333%;
      }

      .offsetsm5 {
        margin-left: 41.666667%;
      }

      .offsetsm6 {
        margin-left: 50%;
      }

      .offsetsm7 {
        margin-left: 58.333333%;
      }

      .offsetsm8 {
        margin-left: 66.666667%;
      }

      .offsetsm9 {
        margin-left: 75%;
      }

      .offsetsm10 {
        margin-left: 83.333333%;
      }

      .offsetsm11 {
        margin-left: 91.666667%;
      }

      .offsetsm12 {
        margin-left: 100%;
      }

      @media ${devices.minLaptopL} {
        .collg1 {
          max-width: 8.33333%;
          flex: 0 0 8.33333%;
        }

        .collg2 {
          max-width: 16.666667%;
          flex: 0 0 16.666667%;
        }

        .collg3 {
          max-width: 25%;
          flex: 0 0 25%;
        }

        .collg4 {
          max-width: 33.33333%;
          flex: 0 0 33.33333%;
        }

        .collg5 {
          max-width: 41.666667%;
          flex: 0 0 41.666667%;
        }

        .collg6 {
          max-width: 50%;
          flex: 0 0 50%;
        }

        .collg7 {
          max-width: 58.333333%;
          flex: 0 0 58.333333%;
        }

        .collg8 {
          max-width: 66.666667%;
          flex: 0 0 66.666667%;
        }

        .collg9 {
          max-width: 75%;
          flex: 0 0 75%;
        }

        .collg10 {
          max-width: 83.333333%;
          flex: 0 0 83.333333%;
        }

        .collg11 {
          max-width: 91.666667%;
          flex: 0 0 91.666667%;
        }

        .collg12 {
          max-width: 100%;
          flex: 0 0 100%;
        }

        .offsetlg1 {
          margin-left: 8.33333%;
        }

        .offsetlg2 {
          margin-left: 16.666667%;
        }

        .offsetlg3 {
          margin-left: 25%;
        }

        .offsetlg4 {
          margin-left: 33.33333%;
        }

        .offsetlg5 {
          margin-left: 41.666667%;
        }

        .offsetlg6 {
          margin-left: 50%;
        }

        .offsetlg7 {
          margin-left: 58.333333%;
        }

        .offsetlg8 {
          margin-left: 66.666667%;
        }

        .offsetlg9 {
          margin-left: 75%;
        }

        .offsetlg10 {
          margin-left: 83.333333%;
        }

        .offsetlg11 {
          margin-left: 91.666667%;
        }

        .offsetlg12 {
          margin-left: 100%;
        }
      }
    }
  }

  .input-range__slider {
    appearance: none;
    background: ${theme.colors.primary};
    border: 2px solid #000000;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.75rem;
    outline: none;
    position: absolute;
    top: 50%;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    width: 1rem;
  }

  .input-range__slider:active {
    transform: scale(1.3);
  }

  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }

  .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    transform: none;
  }

  .input-range__slider-container {
    transition: left 0.3s ease-out;
  }

  .input-range__label {
    color: ${theme.colors.primary};;
    font-size: 0.8rem;
    transform: translateZ(0);
    font-weight: bold;
    white-space: nowrap;
  }

  .input-range__label--min,
  .input-range__label--max {

    color: #aaaaaa;
    bottom: -1.4rem;
    position: absolute;
  }

  .input-range__label--min {
    left: 0;
  }

  .input-range__label--max {
    right: 0;
  }

  .input-range__label--value {
    position: absolute;
    top: -1.8rem;
  }

  .input-range__label-container {
    left: -50%;
    position: relative;
  }

  .input-range__label--max .input-range__label-container {
    left: 50%;
  }

  .input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.3rem;
    position: relative;
    transition: left 0.3s ease-out, width 0.3s ease-out;
  }

  .input-range--disabled .input-range__track {
    background: #eeeeee;
  }

  .input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%;
  }

  .input-range__track--active {
    background: ${theme.colors.primary};
  }

  .input-range {
    height: 1rem;
    position: relative;
    width: 100%;
  }

  /*# sourceMappingURL=index.css.map */
`