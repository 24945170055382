export const theme = {
    colors: {
        // primary: '#CB1E47',
        // secondary: '#BFDAA0',
        // secondaryHover: '#BFDAA0AA',

        primary: '#004AAD',
        secondary: '#BFDAA0',
        secondaryHover: '#BFDAA0AA',

        background: '#F3F3F4',
        title: '#5A5A5A',
        hoverField:'rgba(203, 30, 71, 0.4)',
        boxShadowRedWine:'rgba(234, 76, 137, 0.1)',
    },

    inputBGColors: {
        greyTransparent: '#00000014',
        lightGrey: '#EBEBEB'
    },

    fontSize: {
        text: {
            small:'.8rem',
            normal:'1rem',
            bigger:'1.1rem',
        }
    },

    iconImage: {
        user:'/images/menu/user.svg',
        userMagenta:'/images/menu/user-magenta.svg',
        list:'/images/menu/list.svg',
        listMagenta:'/images/menu/list-magenta.svg',
        add:'/images/menu/add.svg',
        addMagenta:'/images/menu/add-magenta.svg',
        exit:'/images/menu/exit.svg',
        exitMagenta:'/images/menu/exit-magenta.svg',
    }
}