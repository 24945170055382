export const sizes = {
    mobile: '0',
    phone: '480px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
};

export const devices = {
    minMobile: `(min-width: ${sizes.mobile})`,
    minTablet: `(min-width: ${sizes.tablet})`,
    minLaptop: `(min-width: ${sizes.laptop})`,
    minLaptopL: `(min-width: ${sizes.laptopL})`,
    minDesktop: `(min-width: ${sizes.desktop})`,

    maxMobile: `(max-width: ${sizes.phone})`,
    maxTablet: `(max-width: ${sizes.tablet})`,
    maxLaptop: `(max-width: ${sizes.laptop})`,
    maxLaptopL: `(max-width: ${sizes.laptopL})`,
    maxDesktop: `(max-width: ${sizes.desktop})`,
};