import GlobalStyle from "../styles/GlobalStyle"
import {ThemeProvider} from 'styled-components'
import {theme} from "../utils/theme"
import {SessionProvider} from "next-auth/react";
import { useRouter } from 'next/router'
import * as gtag from '../utils/gtag';
import {useEffect} from "react";
import Script from 'next/script';

function MyApp({
                   Component, pageProps: {session, ...pageProps},
               }) {

    const router = useRouter()
    useEffect(() => {
        const handleRouteChange = (url) => {
            gtag.pageview(url)
        }
        router.events.on('routeChangeComplete', handleRouteChange)
        router.events.on('hashChangeComplete', handleRouteChange)
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
            router.events.off('hashChangeComplete', handleRouteChange)
        }
    }, [router.events])
    return (
        <>
            <Script
                strategy="afterInteractive"
                src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
            />
            <Script
                id="gtag-init"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
                }}
            />

            <SessionProvider session={session}>
                <GlobalStyle/>
                <ThemeProvider theme={theme}>
                    <Component session={session} {...pageProps} />
                </ThemeProvider>
            </SessionProvider>
        </>
    )
}

export default MyApp